export interface Routine {
    routineName: string
    variableName: string
    parameters: { [key: string]: any }
}

export interface AssaySequence {
    name: string
    channel: number
    channelTime?: | number
    flowPressure: number | string | string[]
    flowEffort?: number | string | string[]
    degasserPressure: number | string | string[]
    degasserEffort?: number | string | string[]
    escapeWhen: string
    routines?: Routine[]
}

export interface SensorConfiguration {
    numColumns: number
    numRows: number
    spotRadius: number
    columnCentersSpacing: number
    rowCentersSpacing: number
    fiducialSpots?: number[]
}

export interface CameraConfiguration {
    initialExposure: number
    initialFocus: number
}

export interface CalculatedValue {
    name: string
    length: number
    trigger: string
    baselineGap: number
    baselineLength: number
    type: string
}

export interface Channel {
    name: string
    verboseName?: string
    spotNumbers: number[]
    channelIdx: number
    display: boolean
    calibratedEquation?: string | string[]
    units: string
}

export interface NamedSpotRange {
    name: string
    spotNumbers: number[]
}

export interface Configuration {
    assaySequence: AssaySequence[]
    sensorConfiguration: SensorConfiguration
    cameraConfiguration : CameraConfiguration
    calculatedValues: CalculatedValue[]
    channelConfiguration: Channel[]
    namedSpotRanges: NamedSpotRange[]
}

function validateRoutine(obj: any, path: string): string[] {
    const errors: string[] = [];

    if (typeof obj.routineName !== 'string') {
        errors.push(`${path}.routineName is missing or not a string`);
    }
    if (typeof obj.variableName !== 'string') {
        errors.push(`${path}.variableName is missing or not a string`);
    }
    if (
        (typeof obj.parameters !== 'undefined') &&
        (typeof obj.parameters !== 'object' ||
        obj.parameters === null)
    ) {
        errors.push(`${path}.parameters is missing or not an object`);
    }

    return errors;
}

function validateFailCase(obj: any, path: string): string[] {
    const errors: string[] = [];

    if (typeof obj.expression !== 'string' || obj.expression === '') {
        errors.push(`${path}.expression is missing or not a string or empty`);
    }
    if (typeof obj.reason !== 'string' || obj.reason === '') {
        errors.push(`${path}.reason is missing or not a string or empty`);
    }

    return errors;
}   

function validateAssaySequence(obj: any, path: string): string[] {
    const errors: string[] = [];

    if (typeof obj.name !== 'string') {
        errors.push(`${path}.name is missing or not a string`);
    }
    if (typeof obj.channel !== 'number') {
        errors.push(`${path}.channel is missing or not a number`);
    }
    if (obj.channelTime !== undefined && typeof obj.channelTime !== 'number') {
        errors.push(`${path}.channelTime is not a number`);
    }
    if (typeof obj.flowPressure !== 'number' && typeof obj.flowPressure !== 'string' && !Array.isArray(obj.flowPressure) &&
        typeof obj.flowEffort !== 'number' && typeof obj.flowEffort !== 'string' && !Array.isArray(obj.flowEffort)) {
            errors.push(`${path}.flowPressure or ${path}.flowEffort is missing or not a number or string or array`);
    }
    if (typeof obj.degasserPressure !== 'number' && typeof obj.degasserPressure !== 'string' && !Array.isArray(obj.degasserPressure) &&
        typeof obj.degasserEffort !== 'number' && typeof obj.degasserEffort !== 'string' && !Array.isArray(obj.degasserEffort)) {
        errors.push(`${path}.degasserPressure or ${path}.degasserEffort is missing or not a number or string or array`);
    }
    if (typeof obj.escapeWhen !== 'string') {
        errors.push(`${path}.escapeWhen is missing or not a string`);
    }
    if (obj.failWhen !== undefined) {
        if (Array.isArray(obj.failWhen)) {
            obj.failWhen.forEach((failCase: any, index: any) => {
                errors.push(...validateFailCase(failCase, `${path}.failWhen[${index}]`));
            });
        } else if (obj.failWhen !== 'string') {
            errors.push(`${path}.failWhen is not a string or array`);
        }
    }
    if (obj.routines !== undefined && Array.isArray(obj.routines)) {
        obj.routines.forEach((routine: any, index: any) => {
            errors.push(...validateRoutine(routine, `${path}.routines[${index}]`));
        });
    } else if (obj.routines !== undefined) {
        errors.push(`${path}.routines is not an array`);
    }

    return errors;
}

function validateSensorConfiguration(obj: any, path: string): string[] {
    const errors: string[] = [];

    if (typeof obj.numColumns !== 'number') {
        errors.push(`${path}.numColumns is missing or not a number`);
    }
    if (typeof obj.numRows !== 'number') {
        errors.push(`${path}.numRows is missing or not a number`);
    }
    if (typeof obj.spotRadius !== 'number') {
        errors.push(`${path}.spotRadius is missing or not a number`);
    }
    if (typeof obj.columnCentersSpacing !== 'number') {
        errors.push(`${path}.columnCentersSpacing is missing or not a number`);
    }
    if (typeof obj.rowCentersSpacing !== 'number') {
        errors.push(`${path}.rowCentersSpacing is missing or not a number`);
    }
    if (obj.fiducialSpots !== undefined) {
        if (!Array.isArray(obj.fiducialSpots)) {
            errors.push("fiducialSpots is not an array");
        }
        if (obj.fiducialSpots.some((num: any) => typeof num !== 'number')) {
            errors.push("fiducialSpots is not an array of numbers");
        }
    }

    return errors;
}

function validateCameraConfiguration(obj: any, path: string): string[] {
    const errors: string[] = [];

    if (typeof obj.initialExposure !== 'number') {
        errors.push(`${path}.initialExposure is missing or not a number`);
    }
    if (typeof obj.initialFocus !== 'number') {
        errors.push(`${path}.initialFocus is missing or not a number`);
    }

    return errors;
}

function validateSpotRange(sensorConfiguration: SensorConfiguration, obj: any, path: string): string[] {
    const errors: string[] = [];

    const maxValidIndex = sensorConfiguration.numColumns * sensorConfiguration.numRows
    if (typeof obj.name !== 'string') {
        errors.push(`${path}.name is missing or not a string`);
    }
    if (!Array.isArray(obj.spotNumbers) || !obj.spotNumbers.every((num: any) => typeof num === 'number')) {
        errors.push(`${path}.spotNumbers is missing or not an array of numbers`);
    }

    obj.spotNumbers.forEach((spotNumber: number, index: number) => {
        if (spotNumber < 1 || spotNumber > maxValidIndex) {
            errors.push(`${path}.spotNumbers[${index}] (${spotNumber}) is out of range for a ${sensorConfiguration.numColumns}x${sensorConfiguration.numRows} grid`);
        }
    });

    return errors;
}

function validateCalculatedValue(obj: any, path: string): string[] {
    const errors: string[] = [];

    if (typeof obj.name !== 'string') {
        errors.push(`${path}.name is missing or not a string`);
    }
    if (typeof obj.length !== 'number') {
        errors.push(`${path}.length is missing or not a number`);
    }
    if (typeof obj.trigger !== 'string') {
        errors.push(`${path}.trigger is missing or not a string`);
    }
    if (typeof obj.baselineGap !== 'number') {
        errors.push(`${path}.baselineGap is missing or not a number`);
    }
    if (typeof obj.baselineLength !== 'number') {
        errors.push(`${path}.baselineLength is missing or not a number`);
    }
    if (typeof obj.type !== 'string') {
        errors.push(`${path}.type is missing or not a string`);
    }

    return errors;
}

function validateChannel(obj: any, path: string): string[] {
    const errors: string[] = [];

    if (typeof obj.name !== 'string') {
        errors.push(`${path}.name is missing or not a string`);
    }
    if (obj.verboseName !== undefined && typeof obj.verboseName !== 'string') {
        errors.push(`${path}.verboseName is not a string`);
    }
    if (!Array.isArray(obj.spotNumbers) || !obj.spotNumbers.every((num: any) => typeof num === 'number')) {
        errors.push(`${path}.spotNumbers is missing or not an array of numbers`);
    }
    if (typeof obj.channelIdx !== 'number') {
        errors.push(`${path}.channelIdx is missing or not a number`);
    }
    if (typeof obj.display !== 'boolean') {
        errors.push(`${path}.display is missing or not a boolean`);
    }
    if (obj.calibratedEquation !== undefined && typeof obj.calibratedEquation !== 'string' && !Array.isArray(obj.calibratedEquation)) {
        errors.push(`${path}.calibratedEquation is not a string`);
    }
    if (typeof obj.units !== 'string') {
        errors.push(`${path}.units is missing or not a string`);
    }

    return errors;
}

export function validateConfiguration(obj: any): string[] {
    const errors: string[] = [];

    // Validate fields
    if (obj.displayName !== undefined && typeof obj.displayName !== 'string') {
        errors.push("displayName is not a string");
    }

    if (obj.description !== undefined && typeof obj.description !== 'string') {
        errors.push("description is not a string");
    }

    // Validate assaySequence
    if (!Array.isArray(obj.assaySequence)) {
        errors.push("assaySequence is missing or not an array");
    } else if (obj.assaySequence.length === 0) {
        errors.push("assaySequence was empty")
    } else {
        obj.assaySequence.forEach((seq: any, index: any) => {
            errors.push(...validateAssaySequence(seq, `assaySequence[${index}]`));
        });
    }

    // Validate sensorConfiguration
    if (obj.sensorConfiguration === undefined) {
        errors.push("sensorConfiguration is missing");
    } else {
        errors.push(...validateSensorConfiguration(obj.sensorConfiguration, "sensorConfiguration"));
    }

    // Validate cameraConfiguration
    if (obj.cameraConfiguration === undefined) {
        errors.push("cameraConfiguration is missing");
    } else {
        errors.push(...validateCameraConfiguration(obj.cameraConfiguration, "cameraConfiguration"));
    }

    // Validate calculatedValues
    if (!Array.isArray(obj.calculatedValues)) {
        errors.push("calculatedValues is missing or not an array");
    } else {
        obj.calculatedValues.forEach((val: any, index: any) => {
            errors.push(...validateCalculatedValue(val, `calculatedValues[${index}]`));
        });
    }

    // Validate channelConfiguration
    if (!Array.isArray(obj.channelConfiguration)) {
        errors.push("channelConfiguration is missing or not an array");
    } else {
        obj.channelConfiguration.forEach((ch: any, index: any) => {
            errors.push(...validateChannel(ch, `channelConfiguration[${index}]`));
        });
    }

    // Validate namedSpotRanges
    if (!Array.isArray(obj.namedSpotRanges)) {
        errors.push("namedSpotRanges is missing or not an array");
    } else {
        obj.namedSpotRanges.forEach((range: any, index: any) => {
            errors.push(...validateSpotRange(obj.sensorConfiguration, range, `namedSpotRanges[${index}]`));
        });
    }

    return errors;
}
