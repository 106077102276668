import { Configuration } from "../../../types/Configuration";
import { ValidationError } from "../ValidationError";
import { validateRoutine } from "./utilities";

export const detectChangeInDegasserEffort = (configuration: Configuration): ValidationError[] => {
    if (configuration.assaySequence.length === 0) {
        throw new ValidationError("Assay sequence was empty")
    }

    const errors: ValidationError[] = validateRoutine(configuration, "DetectChangeInDegasserEffort", {
        threshold: ["number", (value: any) => []], // Can be any number (positive and negative)
        controlValue: ["string", (value: any) => {
            const errors: ValidationError[] = [];
            if (!["degasserPressure", "degasserEffort", "flowPressure", "flowEffort"].includes(value)) {
                errors.push(new ValidationError(`method "${value}" not recognised`))
            }
            return errors
        }, { optional: true }],
        method: ["string", (value: any) => {
            const errors: ValidationError[] = [];
            if (!["average", "snr"].includes(value)) {
                errors.push(new ValidationError(`method "${value}" not recognised`))
            }
            return errors
        }, { optional: true }],
        referenceInterval: ["number", (value: any) => [], { optional: true }],
        referenceFixedOffset: ["number", (value: any) => [], { optional: true }],
        gapInterval: ["number", (value: any) => [], { optional: true }],
        currentInterval: ["number", (value: any) => [], { optional: true }],
        noiseThreshold: ["number", (value: any) => [], { optional: true }]
    })

    return errors
}
