import { withM3AmplifyV6AzureAuthenticator } from "../authentication/withM3AmplifyV6AzureAuthenticator";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { App } from './App';
import { ResultPressureRig } from '../views/ResultPressureRig';
import { ResultFlowTestRig } from '../views/ResultFlowTestRig';
import { RedirectToDefaultPath } from './RedirectToDefaultPath';
import { ErrorPage } from '../views/ErrorPage';
import { Configuration } from '../views/Configuration';

const AppWrapper = () => {
  const router = createBrowserRouter([
    {
      path: "/result/chipflowTests/:yearMonth/:dateString/:filename",
      element: <ResultFlowTestRig />,
      errorElement: <ErrorPage />
    },
    {
      path: "/result/pressureRigTests/:yearMonth/:dateString/:filename",
      element: <ResultPressureRig />,
      errorElement: <ErrorPage />
    },
    {
      path: "/result/:tenant/:device/:uuid/:timestamp",
      element: <ResultFlowTestRig />,
      errorElement: <ErrorPage />
    },
    {
      path: "/overview/:rigType/:dateString",
      element: <App />,
      errorElement: <ErrorPage />
    },
    {
      path: "/configurations/:dateString",
      element: <App />,
      errorElement: <ErrorPage />
    },
    {
      path: "/configuration/:filename",
      element: <Configuration />,
      errorElement: <ErrorPage />
    },
    {
      path: "*",
      element: <RedirectToDefaultPath />,
      errorElement: <ErrorPage />,
    }
  ]);
  return <RouterProvider router={router} />
};

export default withM3AmplifyV6AzureAuthenticator(AppWrapper);
