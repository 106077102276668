// see:
// * https://docs.amplify.aws/javascript/build-a-backend/troubleshooting/migrate-from-javascript-v5-to-v6/
// * "Exising Resources" Tab - https://docs.amplify.aws/javascript/build-a-backend/auth/set-up-auth/#set-up-and-configure-amplify-auth

import { ResourcesConfig, Amplify } from 'aws-amplify';

const configurationBucket = "cdkm3handheld-m3handheldcomconfigurationfilebucket-is08ced4fgsb"

export const configAmplifyForConfigurationsBucket = () => {
  configAmplify(configurationBucket)
}

export const configAmplifyForUploadBucket = () => {
  configAmplify()
}

export const configAmplifyForTenantBucket = (tenant: string) => {
  const tenantId = ['attomarker', 'carus'].indexOf(tenant)
  const tenantBuckets = ['m3-iot-development-dataattomarker415d2dab-q0xatf1ehkip', 'm3-iot-development-datacarus9974245f-xhn4ojkboop6']
  configAmplify(tenantBuckets[tenantId])
}


export const configAmplify = (bucket: string = "cdkm3handheld-m3handheldcomuploadbucket202f1e47-1s49wrd1ynig") => {
  Amplify.configure(amplifyV6AzureCognitoConfiguration(bucket), {
    Storage: { // see: https://docs.amplify.aws/javascript/build-a-backend/storage/configure-access/#customize-object-key-path
      S3: {
        prefixResolver: async () => ''
      }
    }});
}

export const amplifyV6AzureCognitoConfiguration = (bucket: string): ResourcesConfig => {
  return  {  
    Auth: {
    Cognito: {
      userPoolId: "eu-west-2_inmW0JHyZ",
      userPoolClientId: "6ngjt34vtr2nhe466mhsqog9om",
      identityPoolId: "eu-west-2:00d1feac-1974-41bd-925e-433132551ac0",
      signUpVerificationMethod: 'code',

      loginWith: {
        oauth: {
          domain: "auth.m3handheld.com",
          scopes: ["email", "openid", "aws.cognito.signin.user.admin"],
          redirectSignIn: ["http://localhost:3000/", "https://m3handheld.com/"],
          redirectSignOut: ["http://localhost:3000/", "https://m3handheld.com/"],
          responseType: "code"
          }
      }
    }
    },
    Storage: {
    S3: {
      region: "eu-west-2",
      bucket: bucket
    }
  }
}
}
  